<template>
	<PopupLayer	>
		<template
			v-slot:body
		>
			<div
				v-if="wallet && wallet.address"
				class=" ma-auto width-400 radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">MAFIA NFT 입금</h4>
					<v-icon
						class="color-white"
						@click="$emit('click')"
					>mdi-close-circle</v-icon>
				</div>
				<div class="bg-white text-center pa-20">
					<div
						v-if="wallet"
					>
						<div
							style="width: 256px"
							class="ma-auto"
						>
							<qr-code :text="wallet.address"></qr-code>
						</div>
						<div class="mt-20 flex-row justify-space-between items-center">
							<p>{{ wallet.address }}</p>
							<button class="btn_copy" @click="copy"><v-icon>mdi-content-copy</v-icon></button>
						</div>
					</div>

					<div
						v-else
						class="none"
					>생성된 지갑이 없습니다</div>

					<div class="mt-20">
						<button class="btn-inline btn-primary radius-20 pa-10-20" @click="$emit('click')">확인</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>

import Vue from 'vue'
import VueQRCodeComponent from 'vue-qrcode-component'
import PopupLayer from "@/view/Layout/PopupLayer";

Vue.component('qr-code', VueQRCodeComponent)

export default {
	name: 'mafia035'
	, props: ['user', 'blockchain_div_code', 'token']
	, components: { PopupLayer }
	, data: function(){
		return {
			wallet: {}
		}
	}
	, methods: {
		clipBoard: function (val){
			const t = document.createElement("textarea");
			document.body.appendChild(t);

			t.value = val;
			console.log('t.value', t.value)
			t.select();
			t.setSelectionRange(0, 9999)
			document.execCommand('copy');
			document.body.removeChild(t);
		}

		,copy: function (){
			console.log('this.wallet.address', this.wallet.address)
			this.clipBoard(this.wallet.address);
			this.$bus.$emit('notify',  { type: 'success', message: '지갑주소가 복사되었습니다'})
		}

		,getWalletAddress: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.blockchain_div_code
					}
					, type: true
				})

				if(result.success){
					this.$set(this.wallet, 'address', result.data.wallet_address)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}
		}
	}

	, created() {
		this.getWalletAddress()
		console.log('wallet', this.wallet)
	}
}

</script>