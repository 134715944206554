<template>
	<div class="wrap">
		<div class="bg_w">
			<div class="container">
				<div class="row">
					<div class="flex_wrap">
						<div class="intro_content maf006">
							<div class="intro_top character2">
								<div class="text_guide pt-20">
									<h4>OTP 인증</h4>
								</div>
								<div
									@click="$emit('cancel')"
									class="mt-10" style="font-size: 12px; text-align: center; font-weight: normal"
								><v-icon small>mdi mdi-arrow-left</v-icon> 돌아가기</div>

							</div>
						</div>
						<div class="intro_btn_wrap">
							<div class="bright_input input_area">
								<label for="tit otpNum">OTP 인증번호</label>
								<div class="input_utill">
									<input v-model="gogle_otp_certfc_number" type="text" id="otpNum" placeholder="6자리 인증번호 입력" maxlength="6">
								</div>
							</div>
							<div class="btn_area pt-20">
								<button class="btn_l btn_fill_blue" :disable="gogle_otp_certfc_number.length < 6" @click="postOtp">확인</button>
							</div>
							<a
								v-if="is_otp_find"
								class="otp_find pt-20" @click="$bus.$emit('to', { name: 'mafia1113'})">OTP를 사용할 수 없으신가요?<i class="icon-arrow-right"></i></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'wallet_otp'
	, props: ['user', 'options']
	, data: function(){
		return {
			gogle_otp_certfc_number: ''
		}
	}
	, computed: {
		otp_type: function(){
			let type = 'set'

			if(this.options){
				type = this.options.otp_type
			}else if(this.$route.params.otp_type){
				type = this.$route.params.otp_type
			}

			console.log('otp_type', type)
			return type
		}
		, is_otp_find: function(){
			let t = true
			if(this.otp_type == 'pin_confirm'){
				t = false
			}
			return t
		}
	}
	, methods: {
		postOtp: function(){
			this.$emit('click', this.gogle_otp_certfc_number)
		}
	}

}
</script>