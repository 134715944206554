<template>
	<PopupLayer	>
		<template
			v-slot:body
		>
			<div
				class=" ma-auto width-400 radius-20"
			>
				<div class="pa-10-20 bg-primary flex-row justify-space-between">
					<h4 class="color-white">MAFIA NFT 출금</h4>
					<v-icon
						class="color-white"
						@click="$emit('cancel')"
					>mdi-close-circle</v-icon>
				</div>
				<div class="bg-white pa-20 text-center">
					<div class="">
						<div class="">
							<input
								v-model="item.address"
								type="text" placeholder="출금 주소 입력"
								:rules="[$rules.only_num_alpha(item, 'address')]"
								maxlength="255"
								class="input-box radius-20 flex-1 pa-10-20"
								@change="getTransCommis"
							>
						</div>
						<div class="mt-10 flex-row justify-space-between">
							<input
								v-model="item.amount"
								type="number" placeholder="출금 금액 입력"
								:rules="[$rules.max(item, 'amount', 25)]"
								class="input-box radius-20 flex-1 pa-10-20 mr-10"
							>
							<button
								@click="max"
								class="btn-inline btn-primary-outline radius-20"
							>최대</button>
						</div>
						<div class="mt-30 flex-row justify-space-between">
							<span class="result_name">출금 가스비</span>
							<strong class="data">{{ item.tranmsn_commis }}<em class="pl-5 unit">{{ text_unit }}</em></strong>
						</div>
					</div>
					<div class="mt-30">
						<button
							class="btn-inline btn-dark-gray radius-20 mr-10"
							@click="$emit('cancel')"
						>취소</button>
						<button
							class="btn-inline btn-primary radius-20"
							@click="postWithdrawal"
							:disabled="disabled"
						>출금신청</button>
					</div>
				</div>
			</div>
		</template>
	</PopupLayer>
</template>

<script>
import PopupLayer from "@/view/Layout/PopupLayer";
export default {
	name: 'mafia033'
	, props: ['rules', 'wallet', 'user']
	, components: { PopupLayer }
	, data: function(){
		return {
			item: {
				address: ''
				, amount: ''
				, tranmsn_commis: 0
			}
			, tranmsn_commis: 0
			, walletwallet_address: ''
		}
	}
	, computed: {
		disabled: function(){
			let t = true

			if(this.item.address && this.item.amount > 0){
				t = false
			}

			return t
		}
		, text_unit: function(){
			let t = ''
			switch (this.wallet.coin_token_code){
				case 'BC00200002':
					t = 'KLAY'
					break
				case 'BC00200001':
					t = 'ETH'
					break
				case 'BC00200003':
					t = 'MAF'
					break
				case 'BC00200004':
					t = 'MAF'
					break
				case 'BC00200005':
					t = 'REAP'
					break
			}
			return t
		}
	}
	, methods: {
		postWithdrawal: function(){
			if(!this.item.address){
				console.log('!this.item.address')
				return this.$bus.$emit('notify', { type: 'error', message: '출금주소를 입력하세요'})
			}else if(!this.item.amount) {
				console.log('!this.item.amount')
				return this.$bus.$emit('notify', {type: 'error', message: '출금수량을 입력하세요'})
			}else if(Number(this.item.amount) <= 0){
				console.log('Number(this.item.amount) <= 0')
				return this.$bus.$emit('notify', { type: 'error', message: '출금수량을 확인하세요'})
			}else if(this.item.amount > this.wallet.amount ) {
				console.log('this.item.amount > this.wallet.amount')
				return this.$bus.$emit('notify', { type: 'error', message: '출금수량이 부족합니다'})
			}else{
				console.log('click')
				this.$emit('click', this.item)
			}
		}
		, max: function(){
			this.item.amount = this.wallet.holding_coin_quantity
		}
		, getWalletAddress: async function(){
			try {
				const result = await this.$request.init({
					method: 'post'
					, url: this.$api_url.api_path.get_wallet_address
					, data: {
						member_number: this.user.member_number
						, blockahin_div_code: this.wallet.blockahin_div_code
					}
					, type: true
				})

				if(result.success){
					this.walletwallet_address = result.data.wallet_address
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
			}
		}

		, getTransCommis: async function(){
			try{

				const result = await this.$request.init({
					method: 'post'
					,url: this.$api_url.api_path.get_trans_commis
					,data: {
						member_number: this.user.member_number
						, blockchain_div_code: this.wallet.blockchain_div_code
						, coin_token_code: this.wallet.coin_token_code
						, receive_wallet_address: this.item.address
					}
					,type: true
				})

				if(result.success){
					this.item.tranmsn_commis = result.data.tranmsn_commis
				}else{
					throw result.message
				}
			}catch(e){
				this.$bus.$emit('notify',  { type: 'error', message: e})
				console.log(e)
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}

	, async created() {
		console.log('wallet', this.wallet)
	}
}

</script>